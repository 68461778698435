import React from 'react'
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { EmptyState } from '../../../../libs/EmptyState/index';
import emptyBooking from '.././k2.png';
import { HotelTicketItem } from "../../../Common/BookingItem/HotelItem";
import { Modal } from '../../../../libs/Modal/Modal';
import { FloatInput } from '../../../../libs/FloatLabelInput';
import { showToast } from '../../../../libs/Toast/ToastCallback';

class View extends React.Component {


	constructor(props) {
		super(props);
		this.presentor = props.presentor;
		this.presentor.setView(this);
		this.state = {
			bookings: [],
			emptyState: false,
			cancelTicketHotel: false,
			transId: undefined,
			cancellationAmount: undefined,
			loading:false
		}
	}

	componentDidMount() {
		this.presentor.init();
	}


	componentWillUnmount() {
		this.presentor.destroy();
	}

	load = r => {
		if (r.tickets.length > 0) {
			this.setState({
				bookings: r.tickets,
				emptyState: false
			})
		} else {
			this.setState({
				boooking: r,
				emptyState: true
			})
		}
	}

	Item = (item, index) => {
		return (
			<HotelTicketItem openCancel={this.cancelTicketModal} onCancel={true} data={item} key={index} />
		)
	}


	cancelTicketModal = (transId) => {
		this.setState({
			...this.state,
			cancelTicketHotel: true,
			transId
		}, () => this.presentor.cancellationFare(this.state.transId))

	}

	onFetchFare = r => {
		this.setState({
			...this.state,
			cancelFare: true,
			cancellationAmount: r.amount
		})
	}

	onCancelTicket = r => {
		this.setState({
			...this.state,
			cancelTicketHotel: false,
			cancellationAmount: undefined,
			transId: undefined,
			loading:false
		})
		if(this._remarks && this._remarks.value) {
			this._remarks.value  = ""
		}
		this.presentor.init();
	}
	
	closeLoading = () => {
		this.setState({
			...this.state,
			loading:false
		})
	}

	render() {
		if (this.state.emptyState) {
			return <EmptyState image={emptyBooking} title="No Upcoming Booking Found" desc="Please Book the Tickets" />
		}
		return (
			<div>
				<NormalTable headerStyle={{
					border: 'none'
				}} headers={[
					{
						key: 'details',
						name: '',
						weight: ''
					},
					{
						key: 'name',
						name: '',
						weight: ''
					},
					{
						key: 'amount',
						name: '',
						weight: ''
					}
				]}
					data={this.state.bookings}
					pagination={true}
					mapItem={this.Item}
				/>

				<Modal
					show={this.state.cancelTicketHotel}
					close={() => {
						this.setState({
							cancelTicketHotel: false
						})
					}}
					title="Refund Ticket"	
					des="Please see the cancellation ticket fees"
					hideButton={true}
					onSave={() => {
						
				    }}
				>
					{
						this.state.cancelFare  ?
								<>
								{this.state.cancellationAmount>0 && 
								 <div>
									<p style={{
										textAlign: 'center',
										fontWeight: 700,
									}}>Refundable Amount is {this.state.cancellationAmount}</p>
								</div>
								}
								</>
							  : null
							
					}
					<div>
						<FloatInput title="Enter Remarks" type="text" handleRef={({ ref }) => {
							this._remarks = ref;
						}} />
					</div>
					{this.state.loading ?
						(<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
							<div className="dot-pulse" />
						</div>
						) : (
							<div onClick={() => {
								let remarks = this._remarks.value;
								if (!remarks) {
									showToast({
										type: 'error',
										message: 'Please enter remarks'
									})
									return
								}
								this.setState({
									...this.state,
									loading: true
								})
								this.presentor.cancelTicket(this.state.transId, remarks)
							}} style={{
								height: 30,
								marginTop: 20,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}} className="btn btn-portlet">
								Cancel
							</div>
						)
					}		
				</Modal>
			</div>
		)
	}

}

export default View