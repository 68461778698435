import { get, post } from "../../../../../Model/Network/Config/Axios";


class Model {

    constructor() {
        this.Disposables = [];
    }

    destroy = () => {
        this.Disposables.forEach(item => {
            if (item.isCancellable())
                item.cancel()
        })
    }

    fetch = (cb) => {
        this.Disposables.push(get(`/hotels/bookings.upcoming`, cb));
    }

    fetchFare = (id, cb) => {
        this.Disposables.push(get(`/hotels/cancel/${id}`, cb));
    }

    cancelTicket = (id,remarks, cb) => {
        this.Disposables.push(post(`/hotels/cancel/${id}`, {remarks}, cb));
    }

}

export default Model