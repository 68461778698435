import React from 'react';
import './traveldesk.css'
import { MiniSidebar } from "./Common/MiniSidebar";
import { Redirect, Route, Switch } from 'react-router-dom'
import Hotels from './Hotels'
import Flights from './Flights'
import Cabs from './Cabs'
import Home from './Home'
import Travellers from './Travellers'
import Bus from './Bus'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { getLuna } from "./Common/Luna/Luna";
import Train from './Train';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ToastContainer } from '../libs/fToast/ToastContainer';

class Pager extends React.Component {

	constructor(props) {
		super(props)
		this._handleExpand = this._handleExpand.bind(this)
		this.state = {
			fullScreen: false
		}
	}


	componentDidMount() {
		getLuna().init()
	}

	_handleExpand() {
		let expanded = !this.state.expanded;
		this.setState({
			expanded
		})
	}


	render() {
		if (!this.props.auth.authenticated) {
			return (
				<Redirect to="/" />
			)
		}
		return (
			<div id="traveldesk">
				<ToastContainer/>
				<MiniSidebar {...this.props} />
				<Switch>
					<Route path="/app/home" component={Home} />
					<Route path="/app/hotel" component={Hotels} />
					<Route path="/app/flights" component={Flights} />
					<Route path="/app/bus" component={Bus} />
					<Route path="/app/cabs" component={Cabs} />
					<Route path="/app/train" component={Train} />
					<Route path="/app/travellers" component={Travellers} />
				</Switch>
				<ReactTooltip effect="solid" />
			</div>
		)
	}
}

export default connect(state => {
	return {
		auth: state.auth
	}
})(Pager);
