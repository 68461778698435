import React from 'react'
import {SearchableSelect} from "../../../libs/Select/SearchableSelec2";
import {get} from "../../../../Model/Network/Config/Axios";
import { FloatInput } from '../../../libs/FloatLabelInput';
import { showToast } from '../../../libs/Toast/ToastCallback';
import moment from 'moment';

const PAN_REGEX = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
class Employee extends React.Component {



	state = {
		employee : undefined,
		items :[]
	}

	_employeeSelect = employee => {
		this.setState({
			...this.state,
			employee
		})
	}


	onChange = q => {
		get(`/search/employees`,(e,r)=>{
			if(r){
				this.setState({
					...this.state,
					items:r.employees.map(item=>({
						label : item.name,
						des   : item.email,
						value : item.code,
						des1: `Grade: ${item.grade}`
					}))
				})
			}
		},{
			q
		})
	}

	render() {
		return (
			<div>
				<SearchableSelect
					items={this.state.items}
					onChange={this.onChange}
					label={"Employee"}
					placeholder={"Select Employee"} select={this._employeeSelect} value={this.state.employee}/>
				{this.props.passportDetails?
					<>
						<FloatInput title="Passport Number" type="text" handleRef={ref => { this._passport = ref; }} />
						<FloatInput title="Issue Date" type="date" handleRef={ref => this._issue = ref} />
						<FloatInput title="Expiry Date" type="date" handleRef={ref => this._expire = ref} />
					</>: null
				}
				{
					this.props.panDetails && !["INDIA","India","india"].includes(this.props.country) ?
					<FloatInput title="PAN Number" type="text" handleRef={ref => { this._pan = ref; }} /> : null

				}
					<p style={{
						width: '100%',
						textAlign: 'center',
						fontSize: 17,
						cursor:'pointer',
						color:"#fff",
						background: "#7458F6",
						border: "2px solid #7458F6",
						marginTop: 30,
						padding: "12px 20px"
					}} onClick={()=>{
						if(this.state.employee){
							if(this.props.passportDetails){
								let number = this._passport.value();
								let expiry = this._expire.value();
								let issueDate = this._issue.value();
								if((!number && !expiry && !issueDate) || (number && expiry && issueDate)){
									issueDate = moment(issueDate,"YYYY-MM-DD").valueOf();
									expiry = moment(expiry,"YYYY-MM-DD").valueOf();
									this.props.callback({employee:this.state.employee,...((number && expiry && issueDate))?{passport:{number,expiry,issueDate}}:{}})
								}
								else{
									showToast({
										type:'error',
										message:'Please enter complete passport details'
									})
								}
							}else if(this.props.panDetails && !["INDIA","India","india"].includes(this.props.country)){
								let pan = this._pan.value();
								if(!pan || !pan.match(PAN_REGEX)){
									showToast({
										type:'error',
										message:'Please enter valid PAN number'
									})
									return
								}
								this.props.callback({employee:this.state.employee,pan})
							}
							else{
								this.props.callback({employee:this.state.employee})
							}
							
						}
					}} className="save">
						SELECT EMPLOYEE
					</p>
			</div>
		)
	}

}

export default Employee