import React from 'react'
import {FloatInput} from "../../../libs/FloatLabelInput";
import {ShownSelect} from "../../../libs/Select/index3";
import {showToast} from "../../../libs/Toast/ToastCallback";
import {post} from "../../../../Model/Network/Config/Axios";
import Moment from 'moment';
import moment from 'moment';

const PAN_REGEX = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
class AddGuest extends React.Component {

	state = {
		gender:{
			value: "MALE",
			label: "Male"
		},
	}

	_addGuest = () => {
		let firstName = this._firstName.value();
		let lastName = this._lastName.value();
		let emailAddress = this._emailAddress.value();
		let mobileNumber = this._mobileNumber.value();
		let gender = this.state.gender.value;
		let dob = this._date.value();
		dob = Moment(dob).valueOf();

		let data = {
			name:{
				first:firstName,
				last:lastName
			},
			email:emailAddress,
			mobile:mobileNumber,
			gender,
			dob
		}
		if(!firstName){
			showToast(
				{
					type:'error',
					message:"Please enter a valid first name"
				}
			)
			return;
		}
		if(!lastName){
			showToast(
				{
					type:'error',
					message:"Please enter a valid last name"
				}
			)
			return;
		}
		if(!emailAddress){
			showToast(
				{
					type:'error',
					message:"Please enter a valid email address"
				}
			)
			return;
		}
		if(!mobileNumber){
			showToast(
				{
					type:'error',
					message:"Please enter a valid mobile number"
				}
			)
			return;
		}
		if(!dob) {
			showToast({
				type:'error',
				message:'Please enter a Date of Birth'
			})
			return;
		}
		let number, expiry, issueDate;
		if(this.props.passportDetails){
			number = this._passport.value();
			expiry = this._expire.value();
			issueDate = this._issue.value();
		}
		if((!number && !expiry && !issueDate) || (number && expiry && issueDate)){
			issueDate = moment(issueDate,"YYYY-MM-DD").valueOf();
			expiry = moment(expiry,"YYYY-MM-DD").valueOf();
			post('/guests',data,(e,r)=>{
				if(e){
					showToast({
						type:'error',
						message:e.data.error
					})
				}else{
					if(this.props.passportDetails){
						this.props.callback({guest:{
							label : r.name,
							des : r.email,
							value : r.id,
						},...((number && expiry && issueDate))?{passport:{number,expiry,issueDate}}:{}})
					}else if(this.props.panDetails && !["INDIA","India","india"].includes(this.props.country)){
						let pan = this._pan.value();
						if(!pan || !pan.match(PAN_REGEX)){
							showToast({
								type:'error',
								message:'Please enter valid PAN number'
							})
							return
						}
						this.props.callback({guest:{
							label : r.name,
							des : r.email,
							value : r.id,
						},pan})
					}
					else{
						this.props.callback({guest:{
							label : r.name,
							des : r.email,
							value : r.id,
						}})
					}
				}
			})
		}else{
			showToast({
				type:'error',
				message:'Please enter complete passport details'
			})
		}
	}

	render() {
		return (
			<div>
				<FloatInput title="First Name" type="text"
							handleRef={ref => this._firstName = ref}/>
				<FloatInput title="Last Name" type="text"
							handleRef={ref => this._lastName = ref}/>
				<FloatInput title="Email Address" type="text"
							handleRef={ref => this._emailAddress = ref}/>
				<FloatInput title="Mobile Number" type="text"
							handleRef={ref => this._mobileNumber = ref}/>
				<FloatInput title="Date of Birth" type ="date" 
							handleRef = {ref =>this._date = ref} />
				<ShownSelect placeholder="Select Gender" select={item => {
					this.setState({
						...this.state,
						gender: item
					})
				}} items={[{
					value: "MALE",
					label: "Male"
				}, {
					value: "FEMALE",
					label: "Female"
				}]} value={this.state.gender}/>
				{this.props.passportDetails ?
					<>
						<FloatInput title="Passport Number" type="text" handleRef={ref => { this._passport = ref; }} />
						<FloatInput title="Issue Date" type="date" handleRef={ref => this._issue = ref} />
						<FloatInput title="Expiry Date" type="date" handleRef={ref => this._expire = ref} />
					</> : null
				}
				{
					this.props.panDetails && !["INDIA","India","india"].includes(this.props.country) ?
					<FloatInput title="PAN Number" type="text" handleRef={ref => { this._pan = ref; }} /> : null

				}
				<p style={{
					width: '100%',
					cursor:'pointer',
					textAlign: 'center',
					fontSize: 17,
					color:"#fff",
					background: "#7458F6",
					border: "2px solid #7458F6",
					marginTop: 30,
					padding: "12px 20px"
				}} onClick={this._addGuest} className="save">
					ADD GUEST
				</p>
			</div>
		)
	}

}

export default AddGuest