import React from 'react'
import _ from "lodash";
import { Modal } from "../../../libs/Modal/Modal";
import HotelItem from "./HotelItem";
import { showToast } from "../../../libs/Toast/ToastCallback";
import { KeyValue } from "../../Common/KeyValue";
import NoHotels from './NoHotels'
import { Distance } from '../../../../Utils/Distance';

class HotelsArea extends React.Component {


	state = {
		open: undefined,
		selected: []

	}

	onOpen = open => {
		this.setState({
			open
		})
	}



	_sendSelections = () => {
		let result = this.props.result;
		let selected = this.state.selected;
		if (this.state.selected.length > 0) {
			let selections = []
			result.items.forEach(item => {
				if (_.includes(selected, `${item.hotelType}|${item.code}`)) {
					selections.push({
						image: item.image,
						name: item.name,
						address: item.address.address,
						code: item.code,
						hotelType: item.hotelType,
						ppn: item.pricePerNight
					})
				}
			})
			let requestId = this.props.requestId;
			this.props.postSelection(requestId, selections)
			this.setState({
				...this.state,
				selectionModal: false
			})
		}
	}

	filterData = (data) => {
		let filter = this.props.filter;
		let isChecked = 0;
		let isType = 0;
		let checkRatingStatus = () => {
			filter.Rating.forEach(val => {
				if (val.isActive) {
					isChecked++
				}
			})
		}

		let checkTypeStatus = () => {
			filter.type.forEach(val => {
				if (val.isActive) {
					isType++;
				}
			})
		}

		checkTypeStatus();
		checkRatingStatus();

		return data.items.filter(item => {
			var isAvail = true;
			if (item.pricePerNight < filter.price.min || item.pricePerNight > filter.price.max)
				isAvail = false;
			if (isAvail) {
				for (var i = 0; i < filter.amenities.length; i++) {
					let amentites = filter.amenities[i];
					if (amentites.isCheck && isAvail) {
						isAvail = item.filters.amentities[amentites.value]
					}
				}
			}

			if (filter.hotelCode.length > 0) {
				if (filter.hotelCode === item.code) {
					isAvail = true
				} else {
					isAvail = false
				}
			}
			if (isAvail) {
				if (filter.distance.lat) {
					let lat = filter.distance.lat;
					let lng = filter.distance.lng;
					let distance = Distance(lat, lng, item.address.lat, item.address.lng);
					item.distance = distance;
					if (distance > filter.distance.value) {
						isAvail = false;
					}
				} else {
					item.distance = -1;
				}
			}
			if (isAvail) {
				let isRating = false;
				if (isChecked > 0) {
					filter.Rating.forEach(r => {
						let value = parseInt(r.value, 10);
						if (r.isActive && value === item.filters.rating) {
							isRating = true
						}
						isAvail = isRating;

					})
				}
			}

			if (isAvail) {
				let type = false;
				if (isType > 0) {
					filter.type.forEach(r => {

						if (r.isActive && r.value === 'CLEARTRIP&YATRA') {
							let split = r.value.split('&');
							if (item.hotelType.toLowerCase().toLowerCase().includes(split[0].toLowerCase()) && item.hotelType.toLowerCase().includes(split[1].toLowerCase())) {
								type = true
							}
						} else if (item.hotelType.toLowerCase().includes(r.value.toLowerCase()) && r.isActive) {
							type = true;
						}
						isAvail = type

					})
				}

			}

			return isAvail;
		});
	}

	render() {
		let filter = this.filterData(this.props.result);
		if (this.props.selected) {
			filter.forEach((val, index) => {
				if (`${val.hotelType}|${val.code}` === this.props.selected) {
					let item = null;
					item = filter[0];
					filter[0] = filter[index];
					filter[index] = item;
				}
			})
		}
		let request = this.props.request;
		let result = this.props.result
		return (
			<div>
				<div style={{
					height: 60,
					position: 'relative'
				}}>
					<div style={{
						background: 'white',
						height: 60,
						position: 'absolute',
						top: 7,
						left: 0,
						zIndex: 11,
						paddingLeft: 12,
						right: 0,
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row',
						borderBottom: '1px solid #cdcdcd'
					}}>
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="Country Name" value={request.countryName} />
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="City Name" value={request.cityName} />
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="Check In" value={request.checkInDate} />
						<KeyValue style={{
							marginRight: 18,
							flex: 1
						}} title="Check Out" value={request.checkOutDate} />
						<p onClick={() => {
							if (this.state.selected.length === 0)
								this.props.history.goBack()
							else {
								this.setState({
									...this.state,
									selectionModal: true
								})
							}
						}} style={{
							fontWeight: 600,
							height: 45,
							margin: 4,
							width: 300,
							textAlign: 'center',
							color: "white",
							borderRadius: this.state.selected.length > 0 ? 22 : 0,
							background: this.state.selected.length > 0 ? "#7558F6" : "#6376FA",
							padding: '12px 18px',
							fontSize: 15,
							letterSpacing: '1px',
							textTransform: 'uppercase',
							lineHeight: '20px',
							cursor: 'pointer'
						}}>
							{
								this.state.selected.length > 0 ? `Send ${this.state.selected.length} Selections` : "Reset Search Criteria"
							}
						</p>
					</div>
				</div>
				<Modal
					buttonName="Send Options"
					des="By clicking on button below selected option will be sent to employee."
					style={{
						save: {
							width: '100%',
							textAlign: 'center',
							fontSize: 17,
							padding: "12px 20px",
							background: "#7558F6",
							border: "1px solid #7558F6"
						},
					}} show={this.state.selectionModal} onSave={this._sendSelections} title="Send Selection Options"
					close={() => {
						this.setState({
							...this.state,
							selectionModal: false
						})
					}}>
					<div>
						{
							this.props.result.items.map(i => {
								if (_.includes(this.state.selected, `${i.hotelType}|${i.code}`)) {
									return (
										<div style={{
											display: 'flex',
											height: 60,
											marginBottom: 8
										}}>
											<div style={{
												width: 60,
												height: 60,
												marginRight: 10,
												background: "#C9C8FC",
											}}>
												<img src={i.image} style={{
													width: 60,
													height: 60,
													objectFit: 'cover'
												}} alt="hotel" />
											</div>
											<div style={{
												flex: 1
											}}>
												<p style={{
													fontSize: 15,
													fontWeight: 600,
													color: "#454545"
												}}>
													{i.name}
												</p>
												<p className="twolines" style={{
													fontSize: 13,
													fontWeight: 400,
													color: "#898989",
												}}>
													{
														i.address.address
													}
												</p>

											</div>
										</div>
									)
								}
								return null;
							})
						}
					</div>
				</Modal>
				<div style={{
					height: 'calc( 100vh - 60px)',
					overflowY: 'auto'
				}}>
					{
						filter.length > 0 ? <div style={{
							paddingBottom: 20,
							display: 'flex',
							position: 'relative',
							flexWrap: 'wrap'
						}}>
							{filter.map(i => <HotelItem preferred={this.props.selected}
								selectedHotelId={this.props.selectedHotelId}
								selected={this.state.selected}
								onSelect={this._onSelect}
								requestId={this.props.requestId}
								history={this.props.history}
								searchId={result.searchId}
								sessionId={this.props.sessionId}
								open={this.state.open}
								onOpen={this.onOpen} data={i} key={i.code} country={this.props.country} />)

							}
						</div> : <NoHotels {...this.props} />}
				</div>
			</div>
		)
	}


	_onSelect = code => {
		let selected = this.state.selected;
		if (selected.length > 4) {
			showToast({
				type: 'error',
				message: "Only 5 selection is possible."
			})
			return;
		}
		if (_.includes(selected, code)) {
			selected = selected.filter(value => value !== code);
		} else {
			selected.push(code)
		}
		this.setState({
			...this.state,
			selected
		})
	}


}

export default HotelsArea
